<template>
    <div>
        <v-container>
            <!-- Update tabs to match Dashboard style -->
            <v-card class="mb-4">
                <v-tabs
                    v-model="activeTab"
                    color="white"
                    background-color="rfaccent2"
                    fixed-tabs
                    centered
                    icons-and-text
                    show-arrows
                >
                    <v-tab>
                        Inbox
                        <v-icon>mdi-inbox</v-icon>
                    </v-tab>
                    <v-tab>
                        Sent
                        <v-icon>mdi-send</v-icon>
                    </v-tab>
                </v-tabs>
            </v-card>

            <v-row justify="end">
                <v-col 
                    v-show="$vuetify.breakpoint.lgAndUp || !selectedMessage"
                    cols="12" 
                    lg="3" 
                    class="pt-1 pb-0"
                >
                    <v-text-field
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                        single-line
                        solo
                        hide-details
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="text-right pb-0">
                    <v-btn color="green" @click="openComposeDialog" dark>
                        <v-icon class="mr-1">mdi-email-outline</v-icon>
                        New Message
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-row no-gutters class="inbox-container">
            <!-- Message List -->
            <v-col 
                v-show="$vuetify.breakpoint.lgAndUp || !selectedMessage"
                :class="[
                    'mt-3',
                    'transition-col',
                    {'col-shrink': $vuetify.breakpoint.lgAndUp && selectedMessage}
                ]"
                :style="{
                    width: $vuetify.breakpoint.lgAndUp ? (selectedMessage ? '41.666666%' : '100%') : '100%',
                    transform: selectedMessage ? 'translateX(0)' : 'translateX(0)'
                }"
            >
                <v-data-table
                    :headers="currentHeaders"
                    :items="messages"
                    :loading="loading"
                    :options.sync="options"
                    :search="search"
                    :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
                    :server-items-length="total"
                    :sort-by.sync="options.sortBy"
                    :sort-desc.sync="options.sortDesc"
                    must-sort
                    single-sort
                    :show-select="showSelect"
                    class="inbox-table"
                >
                    <template v-slot:body="{ items, isSelected, select}">
                        <tbody>
                        <tr v-for="item in items" 
                            :key="item.id" 
                            @click="handleRowClick(item)" 
                            :class="{'cursor-pointer': item.type === 'message'}"
                        >
                            <td v-if="showSelect" @click.stop>
                                <v-checkbox
                                    :input-value="isSelected(item)"
                                    hide-details
                                    color="white"
                                    @click="select(item, !isSelected(item))"
                                    class="ma-0 pl-4"
                                >
                                </v-checkbox>
                            </td>
                            <td v-if="showStar" @click.stop>
                                <v-rating
                                    xv-model="item.starred"
                                    clearable
                                    length="1"
                                    color="yellow"
                                    background-color="white"
                                    title="Star Message"
                                >
                                </v-rating>
                            </td>
                            <td>
                                <div v-if="activeTab === 0 && item.from_username">
                                    <div class="d-flex align-center">
                                        <UserAvatarList
                                            :user="{ID: item.from, Username: item.from_username, Callsign: item.from_callsign}"
                                            xsmall
                                            class="mr-2"
                                        />
                                        <div>
                                            <span class="truncate">{{ item.from_username }}</span>
                                            <small class="ml-1 text--secondary">{{ item.from_callsign }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="activeTab === 1 && item.to_username">
                                    <div class="d-flex align-center">
                                        <UserAvatarList
                                            :user="{ID: item.to, Username: item.to_username, Callsign: item.to_callsign}"
                                            xsmall
                                            class="mr-2"
                                        />
                                        <div>
                                            <span class="truncate">{{ item.to_username }}</span>
                                            <small class="ml-1 text--secondary">{{ item.to_callsign }}</small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ formatDate(item.sent) }}</td>
                            <td v-if="activeTab === 1">
                                {{ item.read ? formatDate(item.read) : 'Unread' }}
                            </td>
                            <td class="">
                                <span :class="{'font-weight-bold': activeTab === 0 && !item.read}">{{ item.subject }}</span>
                            </td>
                            <td class="truncate hidden-md-and-down">
                                <span :class="{'font-weight-bold': activeTab === 0 && !item.read}">{{ stripHtml(item.body) }}</span>
                            </td>                            
                            <td class="text-right" @click.stop>
                                <v-btn color="rfaccent2" small v-if="item.type === 'repeater'" @click="openRequestResponseDialog">Respond</v-btn>
                                <v-btn small icon dark class="mr-2 info" title="Reply" v-if="item.type === 'message'" @click="replyToMessage(item)">
                                    <v-icon small>mdi-reply</v-icon>
                                </v-btn>
                                <v-btn 
                                    small 
                                    icon 
                                    dark 
                                    class="mr-2 error" 
                                    title="Delete" 
                                    v-if="item.type === 'message' && activeTab === 0" 
                                    @click="deleteMessage(item.id)"
                                >
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>

            <!-- Message View -->
            <v-col 
                v-if="selectedMessage"
                class="message-view pa-6 transition-col elevation-7 mt-3 mr-3 mb-5"
                :style="{
                    width: $vuetify.breakpoint.lgAndUp ? '58.333333%' : '100%',
                    transform: `translateX(${selectedMessage ? '0' : '100%'})`
                }"
            >
                <div class="message-content transition-content">
                    <div class="d-flex align-center mb-6">
                        <v-btn icon @click="closeMessage" class="mr-4">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <div class="flex-grow-1">
                            <div class="text-h6">{{ selectedMessage.subject }}</div>
                        </div>
                        <v-btn 
                            color="error" 
                            @click="deleteMessage(selectedMessage.id)"
                            v-if="selectedMessage.type === 'message' && activeTab === 0"
                            class="text-none mr-2"
                        >
                            <v-icon left>mdi-delete</v-icon>
                            Delete
                        </v-btn>
                        <v-btn 
                            color="primary" 
                            @click="replyToMessage({
                                id: selectedMessage.id,
                                from: selectedMessage.from,
                                from_username: selectedMessage.from_username,
                                from_callsign: selectedMessage.from_callsign,
                                subject: selectedMessage.subject,
                                body: selectedMessage.body
                            })"
                            v-if="selectedMessage.type === 'message'"
                            class="text-none"
                        >
                            <v-icon left>mdi-reply</v-icon>
                            Reply
                        </v-btn>
                    </div>

                    <v-divider></v-divider>

                    <div class="message-header">
                        <div class="d-flex align-center">
                            <router-link 
                                :to="'/user/' + (activeTab === 0 ? selectedMessage.from_username : selectedMessage.to_username)" 
                                class="no-underline color-inherit mr-3"
                            >
                                <UserAvatarList
                                    :user="{
                                        ID: activeTab === 0 ? selectedMessage.from : selectedMessage.to,
                                        Username: activeTab === 0 ? selectedMessage.from_username : selectedMessage.to_username,
                                        Callsign: activeTab === 0 ? selectedMessage.from_callsign : selectedMessage.to_callsign
                                    }"
                                    small
                                />
                            </router-link>
                            <div class="flex-grow-1">
                                <div class="text-subtitle-1 font-weight-medium">
                                    <router-link 
                                        :to="'/user/' + (activeTab === 0 ? selectedMessage.from_username : selectedMessage.to_username)"
                                        class="no-underline color-inherit"
                                    >
                                        {{ activeTab === 0 ? selectedMessage.from_username : selectedMessage.to_username }}
                                    </router-link>
                                    <small class="text--secondary text-caption ml-2">
                                        <router-link 
                                            :to="'/license/' + (activeTab === 0 ? selectedMessage.from_callsign : selectedMessage.to_callsign)"
                                            class="no-underline color-inherit text--secondary"
                                        >
                                            {{ activeTab === 0 ? selectedMessage.from_callsign : selectedMessage.to_callsign }}
                                        </router-link>
                                    </small>
                                </div>
                                <div class="text--secondary text-caption">
                                    {{ formatDetailDate(selectedMessage.sent) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <v-divider class="mb-6"></v-divider>
                    
                    <div class="message-body text-body-1">
                        <!-- Use conditional rendering based on whether the message contains HTML -->
                        <div v-if="containsHtml(selectedMessage.body)" v-html="sanitizeHtml(selectedMessage.body)"></div>
                        <pre v-else class="plain-text-message">{{ selectedMessage.body }}</pre>
                    </div>
                </div>
            </v-col>
        </v-row>

        <RequestResponse :request="responseRequest.request" v-if="1==2" />

        <!-- Add snackbar for notifications -->
        <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            top
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- Update compose dialog to use Vue2-Editor -->
        <v-dialog v-model="dialogs.compose.state" persistent attach="#main-content" max-width="900">
            <v-card>
                <v-toolbar dense flat dark color="rfaccent2">
                    <v-toolbar-title>Compose Message</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmDiscard">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                
                <v-card-text class="mt-5">
                    <!-- Add error alert -->
                    <v-alert
                        v-if="dialogs.compose.error"
                        type="error"
                        dismissible
                        class="mb-4"
                        @input="dialogs.compose.error = ''"
                    >
                        {{ dialogs.compose.error }}
                    </v-alert>

                    <v-autocomplete
                        v-model="dialogs.compose.to"
                        :items="userSearchResults"
                        :loading="userSearchLoading"
                        :search-input.sync="userSearchQuery"
                        item-text="Username"
                        item-value="ID"
                        label="To"
                        placeholder="Search for a user"
                        outlined
                        hide-no-data
                        :filter="() => true"
                        return-object
                        clearable
                    >
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center">
                                <UserAvatarList 
                                    :user="item"
                                    xsmall
                                    class="mr-2"
                                />
                                <div>
                                    <span>{{ item.Username }}</span>
                                    <small class="ml-1 text--secondary">{{ item.Callsign }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-avatar>
                                <UserAvatarList 
                                    :user="item"
                                    xsmall
                                />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.Username }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.Callsign }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>

                    <v-text-field 
                        v-model="dialogs.compose.subject" 
                        label="Subject" 
                        outlined
                        :error-messages="subjectError"
                    ></v-text-field>

                    <!-- Replace textarea with Vue2-Editor -->
                    <v-sheet light class="mb-4">
                        <vue-editor 
                            v-model="dialogs.compose.body" 
                            :error-messages="bodyError"
                            placeholder="Compose your message here..."
                        ></vue-editor>
                    </v-sheet>
                    <div v-if="bodyError" class="error--text text-caption ml-3 mb-4">
                        {{ bodyError }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="confirmDiscard">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="sendMessage">Send
                        <v-icon class="ml-1" small>mdi-send</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
    .truncate {
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .message-body {
        word-wrap: break-word;
        font-family: inherit;
        line-height: 1.6;
        flex: 1;
        overflow-y: auto;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .message-header {
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 4px;
        padding: 16px;
    }

    .message-view {
        position: relative;
        border-radius: 4px;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .inbox-container {
        position: relative;
        overflow: hidden;
        min-height: calc(100vh - 200px);
    }

    .inbox-table {
        overflow-y: auto;
        width: 100%;
    }

    .transition-col {
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .message-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Remove unused styles */
    .slide-list-enter-active,
    .slide-list-leave-active,
    .slide-message-enter-active,
    .slide-message-leave-active,
    .slide-list-enter-from,
    .slide-list-leave-to,
    .slide-message-enter-from,
    .slide-message-leave-to {
        display: none;
    }

    .v-row {
        flex-wrap: nowrap;
    }

    .col-shrink {
        flex: 0 0 auto;
    }

    /* Add a new style for dark theme background */
    .v-application.theme--dark .message-view {
        /* background-color: rgb(18, 18, 18); */
        background-color: rgb(30, 30, 30);
    }

    /* Add a style for light theme background */
    .v-application.theme--light .message-view {
        background-color: #FFFFFF;
    }

    /* Add styles for the editor */
    .ql-editor {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }
    
    /* Make sure the editor toolbar is visible */
    .ql-toolbar {
        background-color: #f5f5f5;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    /* Add styles for HTML content in messages */
    .message-body img {
        max-width: 100%;
        height: auto;
    }
    
    .message-body a {
        color: var(--v-primary-base);
        text-decoration: none;
    }
    
    .message-body a:hover {
        text-decoration: underline;
    }
    
    .message-body blockquote {
        border-left: 3px solid #ccc;
        margin-left: 0;
        padding-left: 16px;
        color: #666;
    }
    
    .message-body pre {
        /* background-color: #f5f5f5; */
        /* padding: 8px; */
        /* border-radius: 4px; */
        overflow-x: auto;
    }

    /* Add style for plain text messages */
    .plain-text-message {
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin: 0;
        padding: 0;
        background: transparent;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        overflow-x: auto;
    }

    /* Add responsive styles */
    @media (max-width: 960px) {
        .message-view {
            height: calc(100vh - 150px);
        }
    }
</style>

<script>
    import moment from 'moment';
    import UserAvatarList from '@/components/UserAvatarList.vue';
    import RequestResponse from '@/components/Profile/RequestResponse.vue';
    import axios from "axios";
    import config from "@/config";
    import { VueEditor } from 'vue2-editor';

    export default {
        name: 'Inbox',

        props: ['user', 'messageId'],

        components: {
            UserAvatarList,
            RequestResponse,
            VueEditor
        },

        data: () => ({
            activeTab: 0,
            search: null,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['sent'],
                sortDesc: [true]
            },
            headers: {
                inbox: [
                    {text: 'From', value: 'from_username', width: 300, sortable: false},
                    {text: 'Sent', value: 'sent', width: 100},
                    {text: 'Subject', value: 'subject', sortable: false, width: 300},
                    {
                        text: 'Message', 
                        value: 'body', 
                        sortable: false,
                        class: 'hidden-md-and-down' // Hide on small screens
                    },
                    {text: '', value: 'actions', sortable: false, width: 175},
                ],
                sent: [
                    {text: 'To', value: 'to_username', width: 300, sortable: false},
                    {text: 'Sent', value: 'sent', width: 100},
                    {text: 'Read', value: 'read', width: 100},
                    {text: 'Subject', value: 'subject', sortable: false, width: 300},
                    {
                        text: 'Message', 
                        value: 'body', 
                        sortable: false,
                        class: 'hidden-md-and-down' // Hide on small screens
                    },
                    {text: '', value: 'actions', sortable: false, width: 175},
                ]
            },
            showSelect: false, //@todo enable row selection
            showStar: false,
            dialogs: {
                compose: {
                    state: false,
                    to: null,
                    subject: null,
                    body: null,
                    replyTo: null,
                    error: ''  // Add error property
                },
                message: {
                    state: false,
                    message: null,
                    from: null,
                    sent: null,
                    subject: null,
                    body: null,
                }
            },
            total: 0,
            messages: [],
            responseRequest: {
                request: 123
            },
            userSearchQuery: null,
            userSearchResults: [],
            userSearchLoading: false,
            subjectError: '',
            bodyError: '',
            isNavigating: false,
            selectedMessage: null,
            snackbar: {
                show: false,
                text: '',
                color: 'success',
                timeout: 3000
            },
            isUpdatingOptions: false,
        }),

        computed: {
            currentHeaders() {
                return this.headers[this.activeTab === 0 ? 'inbox' : 'sent'];
            }
        },

        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    // Set activeTab based on route
                    this.activeTab = to.path.includes('/sent') ? 1 : 0;
                }
            },
            
            activeTab: {
                handler(newTab) {
                    this.selectedMessage = null;
                    
                    // Update options without directly calling fetchMessages
                    this.options = {
                        ...this.options,
                        page: 1,
                        itemsPerPage: this.options.itemsPerPage || 10,
                        sortBy: ['sent'],
                        sortDesc: [true]
                    };

                    // Update route when tab changes
                    if (!this.messageId) {
                        const path = newTab === 1 ? '/messages/sent' : '/messages';
                        if (this.$route.path !== path) {
                            this.$router.push(path);
                        }
                    }

                    if (this.messageId) {
                        this.loadMessageById(this.messageId);
                    }
                }
            },

            'options.sortBy': {
                handler() {
                    this.fetchMessages();
                }
            },
            
            'options.sortDesc': {
                handler() {
                    this.fetchMessages();
                }
            },
            
            'options.page': {
                handler() {
                    this.fetchMessages();
                }
            },
            
            'options.itemsPerPage': {
                handler() {
                    this.fetchMessages();
                }
            },

            async search() {
                await this.fetchMessages();
            },

            userSearchQuery(query) {
                this.searchUsers(query);
            },

            messageId: {
                immediate: true,
                async handler(newId) {
                    if (newId && !this.isNavigating) {
                        await this.loadMessageById(newId);
                    }
                    this.isNavigating = false;
                }
            },

            // Add watcher for route query parameters
            '$route.query.to': {
                immediate: true,
                async handler(username) {
                    if (username && !this.dialogs.compose.to) {
                        try {
                            this.userSearchLoading = true;
                            const response = await axios.get(
                                `${config.API_LOCATION}/users/search?q=${username}`,
                                { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                            );
                            
                            const user = response.data.items.find(u => 
                                u.Username.toLowerCase() === username.toLowerCase()
                            );
                            
                            if (user) {
                                this.userSearchResults = [user];
                                this.dialogs.compose.to = user;
                                this.dialogs.compose.state = true;
                            }
                        } catch (err) {
                            console.error('Error searching for user:', err);
                        } finally {
                            this.userSearchLoading = false;
                        }
                    }
                }
            }
        },

        methods: {
            formatDate(date) {
                if (!date) return '';
                
                const momentDate = moment(date);
                const today = moment().startOf('day');
                
                // If the date is today, show only time
                if (momentDate.isSame(today, 'day')) {
                    return momentDate.format('h:mm A');
                }
                // If the date is yesterday, show "Yesterday"
                else if (momentDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
                    return 'Yesterday';
                }
                // Otherwise show only the date without time
                else {
                    return momentDate.format('MM/DD/YYYY');
                }
            },

            async fetchMessages() {
                // Skip if we're navigating or already loading
                if (this.isNavigating || this.loading) return;
                
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    this.loading = true;
                    const offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    
                    const endpoint = this.activeTab === 1 ? 'messages/sent' : 'messages';

                    // Use first sort value since we only support single column sort
                    const sortBy = this.options.sortBy[0] || 'sent';
                    const sortDesc = this.options.sortDesc[0] === undefined ? true : this.options.sortDesc[0];

                    const response = await axios.get(
                        `${config.API_LOCATION}/${endpoint}?search=${this.search ? this.search : ''}&limit=${this.options.itemsPerPage}&skip=${offset}&sort=${sortBy}&descending=${sortDesc}`,
                        { headers: axiosHeaders }
                    );
                    
                    this.total = response.data.info.total;
                    this.messages = response.data.messages;                

                    this.$emit('updatemessages', this.messages);
                } catch (err) {
                    console.error('Error fetching messages:', err);
                } finally {
                    // Ensure loading is set to false even if there's an error
                    this.loading = false;
                }
            },

            openRequestResponseDialog(){
                this.responseRequest.request = {
                    test: 456
                };
            },

            async openMessageDialog(message) {
                this.selectedMessage = message;
                
                if (!message.read && message.id) {
                    try {
                        const messageId = message.id;
                        await axios.post(
                            `${config.API_LOCATION}/messages/${messageId}/read`,
                            {},
                            { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                        );
                        message.read = new Date().toISOString();
                        this.$emit('updatemessages');
                    } catch (err) {
                        console.error('Error marking message as read:', err);
                    }
                }
                
                if (!this.messageId) {
                    this.isNavigating = true;
                    this.$router.push(`/messages/message/${message.id}`);
                }
            },

            closeMessage() {
                this.selectedMessage = null;
                
                if (this.messageId) {
                    // Use the correct base path based on activeTab
                    const basePath = this.activeTab === 1 ? '/messages/sent' : '/messages';
                    this.$router.push(basePath);
                }
            },

            openComposeDialog() {
                this.dialogs.compose.state = true;
            },

            closeComposeDialog() {
                this.dialogs.compose = {
                    state: false,
                    to: null,
                    subject: null,
                    body: null,
                    replyTo: null,
                    error: ''
                };
                
                // Remove the 'to' parameter from URL if it exists
                if (this.$route.query.to) {
                    const query = { ...this.$route.query };
                    delete query.to;
                    this.$router.replace({ query });
                }
            },

            confirmDiscard() {
                const defaultMessagePrefix = '\n\n---Original Message---\n';
                const currentBody = this.dialogs.compose.body?.trim() || '';
                const originalBody = (defaultMessagePrefix + this.selectedMessage?.body)?.trim() || '';
                const hasCustomBody = currentBody && currentBody !== originalBody;

                // Only show confirmation if there's non-default content to discard
                if (this.dialogs.compose.subject?.trim() || hasCustomBody) {
                    const confirm = window.confirm('Are you sure you want to discard this message? The draft will not be saved.');
                    if (confirm) {
                        this.closeComposeDialog();
                    }
                } else {
                    // No content to discard, just close
                    this.closeComposeDialog();
                }
            },

            async searchUsers(query) {
                if (!query || query.length < 2) return;
                
                try {
                    this.userSearchLoading = true;
                    const response = await axios.get(
                        `${config.API_LOCATION}/users/search?q=${query}`,
                        { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                    );
                    this.userSearchResults = response.data.items;
                } catch (err) {
                    console.error('Error searching users:', err);
                } finally {
                    this.userSearchLoading = false;
                }
            },

            async replyToMessage(message) {
                // First, create the user object with the correct structure
                const recipientUser = {
                    ID: message.from,
                    Username: message.from_username,
                    Callsign: message.from_callsign
                };
                
                // Add the recipient to userSearchResults if not already present
                if (!this.userSearchResults.some(user => user.ID === recipientUser.ID)) {
                    this.userSearchResults = [recipientUser, ...this.userSearchResults];
                }
                
                // Now set the compose dialog values
                this.dialogs.compose.to = recipientUser;
                this.dialogs.compose.subject = `Re: ${message.subject}`;
                
                // Format the original message for the rich text editor
                let originalContent = message.body;
                
                // If the original message is plain text, convert newlines to <br> tags
                if (!this.containsHtml(originalContent)) {
                    originalContent = originalContent.replace(/\n/g, '<br>');
                }
                
                const sanitizedBody = this.$sanitize(originalContent);
                this.dialogs.compose.body = `<br><br><hr><p><strong>Original Message:</strong></p><p>${sanitizedBody}</p>`;
                this.dialogs.compose.replyTo = message.id;
                this.dialogs.compose.state = true;
            },

            async sendMessage() {
                this.subjectError = '';
                this.bodyError = '';
                this.dialogs.compose.error = '';  // Clear any previous error
                
                if (!this.dialogs.compose.to) {
                    this.subjectError = 'Please select a recipient';
                    return;
                }
                if (!this.dialogs.compose.subject?.trim()) {
                    this.subjectError = 'Subject is required';
                    return;
                }
                
                // Extract plain text from HTML content for validation
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = this.dialogs.compose.body || '';
                const plainText = tempDiv.textContent || tempDiv.innerText || '';
                
                if (!plainText.trim()) {
                    this.bodyError = 'Message body is required';
                    return;
                }

                try {
                    await axios.post(
                        `${config.API_LOCATION}/messages`, 
                        {
                            to: this.dialogs.compose.to.ID,
                            subject: this.dialogs.compose.subject,
                            body: this.dialogs.compose.body,
                            replyTo: this.dialogs.compose.replyTo
                        },
                        { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                    );
                    
                    // Show success message
                    this.snackbar.color = 'success';
                    this.snackbar.text = 'Message sent successfully';
                    this.snackbar.show = true;
                    
                    this.$emit('message-sent');
                    this.closeComposeDialog();
                    await this.fetchMessages();
                } catch (err) {
                    console.error('Error sending message:', err);
                    // Show error in the compose dialog
                    this.dialogs.compose.error = 'Failed to send message. Please try again.';
                    // Also show error snackbar
                    this.snackbar.color = 'error';
                    this.snackbar.text = 'Failed to send message';
                    this.snackbar.show = true;
                }
            },

            async deleteMessage(messageId) {
                if (!confirm('Are you sure you want to delete this message?')) return;
                
                try {
                    await axios.delete(
                        `${config.API_LOCATION}/messages/${messageId}`,
                        { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                    );
                    
                    // If we're in the message detail view, close it
                    if (this.selectedMessage?.id === messageId) {
                        this.closeMessage();
                    }
                    
                    await this.fetchMessages();
                    this.$emit('updatemessages');
                } catch (err) {
                    console.error('Error deleting message:', err);
                    alert('Failed to delete message. Please try again.');
                }
            },

            async loadMessageById(id) {
                try {
                    // Use the correct endpoint based on activeTab
                    const endpoint = this.activeTab === 1 ? 'messages/sent' : 'messages';
                    const response = await axios.get(
                        `${config.API_LOCATION}/${endpoint}/${id}`,
                        { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                    );
                    
                    // Ensure all required fields are present with the correct names
                    const message = {
                        id: response.data.message.id,
                        from: response.data.message.from,
                        from_username: response.data.message.from_username,
                        from_callsign: response.data.message.from_callsign,
                        to: response.data.message.to,
                        to_username: response.data.message.to_username,
                        to_callsign: response.data.message.to_callsign,
                        subject: response.data.message.subject,
                        body: response.data.message.body,
                        sent: response.data.message.sent,
                        read: response.data.message.read,
                        type: response.data.message.type || 'message'  // Default to 'message' if not specified
                    };

                    // Load the message list if it's empty
                    if (this.messages.length === 0) {
                        await this.fetchMessages();
                    }

                    await this.openMessageDialog(message);
                } catch (err) {
                    console.error('Error loading message:', err);
                    this.$router.push('/messages');
                }
            },

            async handleRowClick(item) {
                if (item.type === 'message') {
                    this.selectedMessage = item;
                    
                    if (!item.read && item.id && this.activeTab === 0) {
                        try {
                            const messageId = item.id;
                            await axios.post(
                                `${config.API_LOCATION}/messages/${messageId}/read`,
                                {},
                                { headers: { Authorization: `Bearer ${this.user.authToken}` }}
                            );
                            item.read = new Date().toISOString();
                            this.$emit('updatemessages');
                        } catch (err) {
                            console.error('Error marking message as read:', err);
                        }
                    }

                    // Use the correct route based on activeTab
                    const path = this.activeTab === 1 
                        ? `/messages/sent/${item.id}`
                        : `/messages/${item.id}`;
            
                    this.isNavigating = true;
                    this.$router.push(path);
                }
            },

            // Add a method to strip HTML tags from message body
            stripHtml(html) {
                if (!html) return '';
                
                // Create a temporary div element
                const tempDiv = document.createElement('div');
                
                // Set the HTML content
                tempDiv.innerHTML = html;
                
                // Get the text content
                const plainText = tempDiv.textContent || tempDiv.innerText || '';
                
                // Return the plain text, trimmed and limited to a reasonable preview length
                return plainText.trim().substring(0, 100) + (plainText.length > 100 ? '...' : '');
            },

            sanitizeHtml(html) {
                if (!html) return '';
                return this.$sanitize(html);
            },

            formatDetailDate(dateString) {
                if (!dateString) return '';
                const date = moment(dateString);
                const today = moment().startOf('day');
                
                if (date.isSame(today, 'day')) {
                    return `Today at ${date.format('h:mm A')}`;
                } else if (date.isSame(today.clone().subtract(1, 'day'), 'day')) {
                    return `Yesterday at ${date.format('h:mm A')}`;
                } else {
                    return date.format('MMMM D, YYYY [at] h:mm A');
                }
            },

            // Add method to detect if content contains HTML
            containsHtml(text) {
                if (!text) return false;
                
                // Simple regex to detect HTML tags
                const htmlRegex = /<[a-z][\s\S]*>/i;
                return htmlRegex.test(text);
            },
        },

        mounted() {
            // Initialize by fetching messages
            this.fetchMessages();
        }
    }
</script>
