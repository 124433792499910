<template>
    <div>
        {{ dialogs.review }}

        <v-dialog v-model="dialogs.review.state" persistent max-width="1200">
            <v-card>
                <v-toolbar color="rfaccent2" dense dark>
                    <v-toolbar-title>Review Request</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="closeReviewDialog">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-list v-if="dialogs.review.request && dialogs.review.request.repeater">
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-subtitles-outline</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater.Name
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Repeater Name</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-radio-fm</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ dialogs.review.request.repeater.Frequency.replace('46X', '462') }}
                                            MHz
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Frequency</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-login-variant</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater['PL In']
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Input Tone</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-logout-variant mdi-rotate-180</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater['PL Out']
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Output Tone</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                dialogs.review.request.from.FullName
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Licensee Name</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :to="'/user/' + dialogs.review.request.from.Username"
                                             target="_blank">
                                    <UserAvatarList
                                        :user="dialogs.review.request.from"
                                        small
                                        v-if="dialogs.review.request.from"
                                    />

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ dialogs.review.request.from.Username }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Username</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :to="'/license/' + dialogs.review.request.from.Callsign"
                                             target="_blank">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-certificate</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.from.Callsign
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Callsign</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                $moment(dialogs.review.request.date).format('MM/DD/YYYY h:mm a')
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Date Requested</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account-group</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ dialogs.review.request.members }} Total
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Family Members</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :href="`tel:${dialogs.review.request.phone}`">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-phone</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                dialogs.review.request.phone | formatPhone
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Phone Number</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" class="ma-0 py-0 px-0">
                                <v-list-item two-line :href="`mailto:${dialogs.review.request.from.Email}`">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-email</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.from.Email
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Email Address</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-list>

                    <div v-if="dialogs.review.request">
                        <h3 class="font-weight-light my-2">Repeater Will Be Used For</h3>
                        <div>
                            <ul class="ml-10">
                                <li v-for="(use, index) in dialogs.review.request.uses.split(',')" :key="index">
                                    {{ use }}
                                </li>
                            </ul>
                        </div>

                        <h3 class="font-weight-light mt-5 mb-2">Additional Notes from User</h3>
                        <div class="mb-5">
                            <v-card color="grey lighten-4" light
                                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-10' : 'mx-1'">
                                <v-card-text>
                                    {{ dialogs.review.request.notes }}
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>

                    <v-textarea
                        v-model="dialogs.review.response"
                        label="Detailed Response"
                        hint="This will be sent to the user along with your approval decision. Give the user some extra information about the repeater or give them a reason why you're denying the request."
                        persistent-hint
                        outlined
                        v-if="dialogs.review.request && (dialogs.review.request.to === user.id && this.$_.isNull(dialogs.review.request.approved))"
                    >
                    </v-textarea>

                    <div v-else>
                        <h3 class="font-weight-light mt-5 mb-2">Response from Owner</h3>
                        <div class="mb-5">
                            <v-textarea
                                v-model="dialogs.review.response"
                                label="Detailed Response"
                                hint="This will be sent to the user along with your approval decision. Give the user some extra information about the repeater or give them a reason why you're denying the request."
                                persistent-hint
                                outlined
                                v-if="dialogs.review.request && (dialogs.review.request.to === user.id && !this.$_.isNull(dialogs.review.request.approved))"
                            ></v-textarea>

                            <v-card
                                color="grey lighten-4"
                                light
                                :class="$vuetify.breakpoint.lgAndUp ? 'mx-10' : 'mx-1'"
                                v-else
                            >
                                <v-card-text v-text="dialogs.review.response"></v-card-text>
                            </v-card>
                        </div>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <div class="row my-0">
                        <div v-if="dialogs.review.request && dialogs.review.request.to === user.id" class="col-12">
                            <div class="col-12 text-center"
                                 v-if="isApproved(dialogs.review.request) || isDenied(dialogs.review.request)">
                                <v-chip
                                    :color="isApproved(dialogs.review.request) ? 'success' : 'error'"
                                >
                                    <v-icon>
                                        {{
                                            isApproved(dialogs.review.request) ? 'mdi-check' : 'mdi-close'
                                        }}
                                    </v-icon>
                                    Request&nbsp;<span
                                    v-text="isApproved(dialogs.review.request) ? 'Approved' : 'Denied'"></span>
                                </v-chip>
                            </div>

                            <div class="mx-2 text-center"
                                 v-if="isApproved(dialogs.review.request) || isDenied(dialogs.review.request)">
                                <span>
                                    <em>You have already <span
                                        v-text="isApproved(dialogs.review.request) ? 'approved' : 'denied'">approved</span> this request. Edit the Response from Owner above and click below to change your decision.</em>
                                </span>
                            </div>

                            <div class="d-flex justify-end flex-wrap mt-5">
                                <v-btn
                                    color="error"
                                    :class="$vuetify.breakpoint.smAndDown ? 'my-1' : 'mx-1 my-1'"
                                    :disabled="isDenied(dialogs.review.request)"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    @click="confirmDenyRequest"
                                >
                                    Deny
                                </v-btn>

                                <v-btn
                                    color="success"
                                    :class="$vuetify.breakpoint.smAndDown ? 'my-1' : 'mx-1 my-1'"
                                    :disabled="isApproved(dialogs.review.request)"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    @click="confirmApproveRequest"
                                >
                                    Approve
                                </v-btn>
                            </div>
                        </div>

                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.confirmApprove.state" max-width="600">
            <v-card>
                <v-card-title>Approval Confirmation</v-card-title>
                <v-card-text class="text-center">
                    Are you sure you want to approve this user's request? An email will be sent to them with your
                    response.
                </v-card-text>

                <div class="mx-5 pb-5">
                    <v-btn color="rfaccent2" dark block class="my-1" @click="saveRequest(true)">Yes, confirm</v-btn>
                    <v-btn color="error" block @click="dialogs.confirmApprove.state = false">No, cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.confirmDeny.state" max-width="600">
            <v-card>
                <v-card-title>Denial Confirmation</v-card-title>
                <v-card-text class="text-center">Are you sure you want to deny this user's request? An email will be
                    sent to them with
                    your response.
                </v-card-text>
                <div class="mx-5 pb-5">
                    <v-btn color="rfaccent2" dark block class="my-1" @click="saveRequest(false)">Yes, confirm</v-btn>
                    <v-btn color="error" block @click="dialogs.confirmDeny.state = false">No, cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script>
    import config from '@/config';

    export default {
        name: 'RequestResponse',

        props: ['request', 'response', 'responseEditable', 'responseEdit'],

        data: () => ({
            config: config,
            dialogs: {
                review: {
                    state: false,
                    request: null,
                    response: null,
                    responseEditable: false,
                    responseEdit: null
                },
                confirmApprove: {
                    state: false
                },
                confirmDeny: {
                    state: false
                }
            },
            snackbar: {
                state: false,
                color: 'info',
                text: null,
                icon: null,
                timeout: 10000
            }
        }),

        filters: {
            formatPhone(phone) {
                phone = _.trim(phone);
                let countryCode = phone.substring(0, phone.length - 10);
                if (countryCode === '+1') countryCode = '';
                const areaCode = phone.substring(phone.length - 10, phone.length - 7);
                const exchange = phone.substring(phone.length - 7, phone.length - 4);
                const number = phone.substring(phone.length - 4, phone.length);
                return _.trim(`${countryCode} (${areaCode}) ${exchange}-${number}`);
            }
        },

        methods: {
            isPending(request) {
                return request.approved === null;
            },

            isApproved(request) {
                return request.approved === 1;
            },

            isDenied(request) {
                return request.approved === 0;
            },

            getRequestType(n) {
                return n === 0 ? 'Pending' : n === 1 ? 'Approved' : 'Denied';
            },
        }
    }
</script>
