<template>
    <v-container>
        <AdBTWR v-if="!user.isAdFree"/>

        <h1 class="page-header font-weight-light">Messages</h1>

        <div v-if="user.authenticated" class="mb-15">

            <Inbox 
                :user="user" 
                :messageId="$route.params.messageId"
                @updatemessages="handleUpdateMessages"
            />

            <Requests :user="user" v-if="1==2" />
        </div>

        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to view your messages.
            </v-alert>
        </div>

    </v-container>
</template>

<script>
    import { useTitle } from '@vueuse/core';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import Inbox from '@/components/Profile/Messages';
    import Requests from '@/components/Profile/Requests';

    export default {
        name: 'InboxPage',

        props: ['user'],

        components: { AdBTWR, Inbox, Requests },

        methods: {
            handleUpdateMessages(messages) {
                this.$emit('updatemessages', messages);
            },

            updatePageTitle(route) {
                const isSent = route.path.includes('/sent');
                const isDetail = route.params.messageId;
                let title = 'Messages';
                
                if (isDetail) {
                    title = isSent ? 'Sent Message' : 'Inbox Message';
                } else {
                    title = isSent ? 'Sent Messages' : 'Inbox';
                }
                
                useTitle(`${title} - myGMRS.com`);
            }
        },

        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    this.updatePageTitle(to);
                }
            }
        },

        mounted() {
            this.updatePageTitle(this.$route);
        }
    }
</script>
